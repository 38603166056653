import React, {useState, useEffect, useCallback} from 'react'
import {Spinner} from '@material-tailwind/react'
import { debounce } from 'lodash';
import toast, { Toaster } from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from '@heroicons/react/24/solid';

import { encodedAddressPrefix, tippingAddress, ownerAddress, royalty, tip, contentLength, serviceFeeRate, padding } from '../configs/constant';
import { Input } from '@material-tailwind/react'
import { formatAddress } from '../util/format-data'
import { getSignMainMintText, updateMainPayment, latestMainPayment, registerToken } from '../util/api'
import { getAsciiSum } from '../util/format-data';
import { getFeeRate, bytesToHex, buf2hex, textToHex, hexToBytes, getMempoolUtxos, loopTilAddressReceivesMoney, waitSomeSeconds, addressReceivedMoneyInThisTx, pushBTCpmt, calculateFee, getData, isValidTaprootAddress} from '../util/inscribe-util';
import { sendBtcTransaction } from 'sats-connect';
import FeeRateCard from '../components/FeeRateCard';
import { BitcoinNetworkType, signMessage, signTransaction } from 'sats-connect';

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Chip,
  Avatar,
  IconButton,
  Tooltip,
  Slider,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter
} from "@material-tailwind/react";

import { useWallet, useWallets } from '@wallet-standard/react';

export default function Mint() {
  const { wallets } = useWallets();

  const SatsConnectNamespace = 'sats-connect:';

  const isSatsConnectCompatibleWallet = (wallet) => {
      return SatsConnectNamespace in wallet.features;
  }

  const wallet = useSelector(state => state.wallet);

  const { Address, Script, Signer, Tap, Tx } = window.tapscript;

  const [loading, setLoading] = useState(false);

  const handleMint = async (privkey, address, salts) => {
    
    setLoading(true);

    if (!typeof window) return
    if (!window.tapscript) return

    let cryptoUtils = window.cryptoUtils;
    const KeyPair = cryptoUtils.KeyPair;

    let seckey = new KeyPair(privkey);
    let pubkey = seckey.pub.rawX;

    const ec = new TextEncoder();

    const init_script = [
      pubkey,
      'OP_CHECKSIG'
    ];
    
    const init_script_backup = [
        '0x' + buf2hex(pubkey.buffer),
        'OP_CHECKSIG'
    ];

    let init_leaf = await Tap.tree.getLeaf(Script.encode(init_script));
    let [init_tapkey, init_cblock] = await Tap.getPubKey(pubkey, {target: init_leaf});

    const test_redeemtx = Tx.create({
      vin  : [{
          txid: 'a99d1112bcb35845fd44e703ef2c611f0360dd2bb28927625dbc13eab58cd968',
          vout: 0,
          prevout: {
              value: 10000,
              scriptPubKey: [ 'OP_1', init_tapkey ]
          },
      }],
      vout : [{
          value: 8000,
          scriptPubKey: [ 'OP_1', init_tapkey ]
      }],
    });
    
    const test_sig = await Signer.taproot.sign(seckey.raw, test_redeemtx, 0, {extension: init_leaf});
    test_redeemtx.vin[0].witness = [ test_sig.hex, init_script, init_cblock ];
    const isValid = await Signer.taproot.verify(test_redeemtx, 0, { pubkey });

    if(!isValid)
    {
      alert('Generated keys could not be validated. Please reload the app.');
      return;
    }

    let files = [];

    let mimetype = "text/plain;charset=utf-8";

    for(let i = 0; i< salts.length - 1; i++)
    {
      let text = await getSignMainMintText(address, 1, salts[i + 1]);
      let textJson = JSON.parse(text);
      files.push({
        text: JSON.stringify(text),
        name: textToHex(text),
        hex: textToHex(text),
        mimetype: mimetype,
        sha256: ''
      });
    }

    let inscriptions = [];
    let inscriptionAddressList = [];

    for (let i = 0; i < files.length; i++) {

      const hex = files[i].hex;
      const data = hexToBytes(hex);
      const mimetype = ec.encode(files[i].mimetype);

      const script = [
          pubkey,
          'OP_CHECKSIG',
          'OP_0',
          'OP_IF',
          ec.encode('ord'),
          '01',
          mimetype,
          'OP_0',
          data,
          'OP_ENDIF'
      ];

      const script_backup = [
          '0x' + buf2hex(pubkey.buffer),
          'OP_CHECKSIG',
          'OP_0',
          'OP_IF',
          '0x' + buf2hex(ec.encode('ord')),
          '01',
          '0x' + buf2hex(mimetype),
          'OP_0',
          '0x' + buf2hex(data),
          'OP_ENDIF'
      ];

      const leaf = await Tap.tree.getLeaf(Script.encode(script));
      const [tapkey, cblock] = await Tap.getPubKey(pubkey, { target: leaf });

      let inscriptionAddress = Address.p2tr.encode(tapkey, encodedAddressPrefix);

      let prefix = 160;

      let txsize = prefix + Math.floor(data.length / 4);

      inscriptionAddressList.push(inscriptionAddress);

      inscriptions.push(
        {
          leaf: leaf,
          tapkey: tapkey,
          cblock: cblock,
          inscriptionAddress: inscriptionAddress,
          txsize: txsize,
          fee: padding,
          script: script_backup,
          script_orig: script
        }
      );

    }

    let finishFlag = true;
   
    for (let i = 0; i < inscriptions.length; i++) {
      let transactionData;
      await sleep(1000);
      transactionData = await getMempoolUtxos(inscriptions[i].inscriptionAddress);
      if (transactionData.length >= 1){
        await registerToken(address, transactionData[0].txid + ":" + transactionData[0].vout );
        await inscribe(inscriptions[i], transactionData[0].vout, transactionData[0].txid, transactionData[0].value, seckey, address);
        finishFlag = false;
      }
    }

    if (finishFlag == true) {
      await updateMainPayment(privkey);
    }
    
    setLoading(false);
  }

  const inscribe = async(inscription, vout, txid2, amt2, seckey, address) => {
    let _toAddress;
    let _script;
    let toAddress = address;
    if(toAddress.startsWith('tb1q') || toAddress.startsWith('bc1q'))
    {
        _toAddress = Address.p2wpkh.decode(toAddress, encodedAddressPrefix).hex;
        _script = [ 'OP_0', _toAddress ];
        console.log('using p2wpkh', _script);
    }
    else if(toAddress.startsWith('1') || toAddress.startsWith('m') || toAddress.startsWith('n'))
    {
        _toAddress = Address.p2pkh.decode(toAddress, encodedAddressPrefix).hex;
        _script = Address.p2pkh.scriptPubKey(_toAddress);
        console.log('using p2pkh', _script);
    }
    else if(toAddress.startsWith('3') || toAddress.startsWith('2'))
    {
        _toAddress = Address.p2sh.decode(toAddress, encodedAddressPrefix).hex;
        _script = Address.p2sh.scriptPubKey(_toAddress);
        console.log('using p2sh', _script);
    }
    else
    {
        _toAddress = Address.p2tr.decode(toAddress, encodedAddressPrefix).hex;
        _script = [ 'OP_1', _toAddress ];
        console.log('using p2tr', _script);
    }

    const redeemtx = Tx.create({
        vin  : [{
            txid: txid2,
            vout: vout,
            prevout: {
                value: amt2,
                scriptPubKey: [ 'OP_1', inscription.tapkey ]
            },
        }],
        vout : [{
            value: padding,
            scriptPubKey: _script
        }],
    });

    const sig = await Signer.taproot.sign(seckey.raw, redeemtx, 0, {extension: inscription.leaf});
    redeemtx.vin[0].witness = [ sig.hex, inscription.script_orig, inscription.cblock ];

    console.dir(redeemtx, {depth: null});

    let rawtx2 = Tx.encode(redeemtx).hex;
    let _txid2;

    _txid2 = await pushBTCpmt( rawtx2 );
    await sleep(1000);

    if(_txid2.includes('descendant'))
    {
        include_mempool = false;
        inscribe(inscription, vout, txid2, amt2, seckey, address);
        return;
    }

    try {

        JSON.parse(_txid2);
    } catch (e) {
      console.log(_txid2);
    }
    
  }

  const handleRecover = async () => {
    while(true){
      let result = await latestMainPayment("", 1);
      console.log(result);
      for(let data of result.data) {
        await handleMint(data.private_key, data.address, data.inscription_address.split(","));
        // await sleep(1000);
      }
     if (result.total == 0) break;
    }
  }

  const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  return (
    <div className="text-white mt-[65px] w-full max-w-[1500px] md:px-7 px-2 flex flex-col justify-center items-center mb-10 min-h-[600px]">
      <div className="w-[400px]">
        <Button
          onClick={() => {
            handleRecover();
          }}
          fullWidth
        >Recover</Button>
      </div>
      {
        loading ? <Spinner className="h-16 w-16 text-gray-900/50 fixed" color="pink"/> : <></>
      } 
      <Toaster 
        position="top-right"
      />
    </div>
  )
}
