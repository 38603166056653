import React, { useState, useEffect } from 'react'
import { HomeIcon, PencilIcon, BuildingLibraryIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import { 
  Typography,
  Card,
  CardBody,
  Input,
  Slider,
  Button,
  Spinner,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel, 
  slider
} from "@material-tailwind/react";

import {
  Routes,
  Route,
  useNavigate,
  Link
} from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux';
import Header from '../components/Header';
import Footer from '../components/Footer';
import RecoverTapToken from './RecoverTapToken';
import RecoverTransfer from './RecoverTransfer';
import RecoverDmtToken from './RecoverDmtToken';
import Mint from './Mint';
import Runes from './Runes';

export default function Indexer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [tab, setTab] = useState(0); //0: Wallet 1: Mint Domain 2: Explorer
  
  const handleTab = (tab) => {
    setTab(tab);
    switch(tab) {
      case 0:
        navigate('/');
        break;
      case 1:
        navigate('/tap');
        break;
      case 2:
        navigate('/transfer');
        break;
      case 3:
        navigate('/dmt');
        break;
      case 4:
        navigate('/rune');
        break;
    }
  }
  return (
    <div className="flex flex-col gap-5 bg-background items-center w-full">
      <Header tab = {tab} handle = { handleTab}/>
      <Routes>
        <Route path="/" element={<Mint />} />
        <Route path="/tap" element={<RecoverTapToken />} />
        <Route path="/transfer" element={<RecoverTransfer />} />
        <Route path="/dmt" element={<RecoverDmtToken />} />
        <Route path="/rune" element={<Runes />} />
      </Routes>
      <Footer />
    </div>
  )
}
