export const encodedAddressPrefix = 'main';
export const padding = 546;
export const tip = 0;
export const royalty = 10000;
// export const royalty = 444;
export const tippingAddress = 'bc1p3ns3qgpmyv4j4q00kauvf0xnpg42kf9rc5259n5g3247vhr7yqeqcca3fv';
export const ownerAddress = 'bc1px0j5z4srk2u67rl8qkwcyk7agujzds6vpwh26f4hqpx9w9e7x7qqd74ar9';
export const mempoolNetwork = '';
export const include_mempool = true;
export const marketTip = 2000;
export const marketTipAddress = 'bc1p3ns3qgpmyv4j4q00kauvf0xnpg42kf9rc5259n5g3247vhr7yqeqcca3fv';
export const NUMBER_OF_DUMMY_UTXOS_TO_CREATE = 2;
export const DUMMY_UTXO_VALUE = 600;
export const MIN_UTXO_VALUE = 1000;
export const contentLength = 440;
export const serviceFeeRate = 0.05;
