import CryptoJS from 'crypto-js';
const password = process.env.REACT_APP_PASSWORD;

const apiUrl1 = "https://api.tapwallet.io";
const apiUrl2 = "https://api2.tapwallet.io";
const apiUrl4 = "https://api4.tapwallet.io";

export const mintRuneStart = async (randomKey, transactionDatas) => {
  
  const url = `${apiUrl4}/runes/mint/start`;
  const data = {
    randomKey : randomKey,
    transactionDatas : transactionDatas
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const updateRunePayment = async (randomKey, fundAddress) => {
  
  const url = `${apiUrl4}/runes/mint/recover`;
  const data = {
    randomKey: randomKey,
    fundAddress : fundAddress
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

export const runesPaymentIndex = async (key) => {
  
  const url = `${apiUrl4}/runes/mint/index`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const updateMainPayment = async (key, number) => {
  
  const url = 'https://api.tapwallet.io/new_payment/update';
  const data = {
    key: key
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

export const latestMainPayment = async (key, offset = 1, limit = 10) => {

  const url = `https://api.tapwallet.io/new_payment/latest?key=${key}&offset=${offset}&limit=${limit}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const updatePayment = async (key, number) => {
  
  const url = 'https://api.tapwallet.io/random_payment/update';
  const data = {
    key: key,
    number: number
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

export const latestPayment = async (key, offset = 1, limit = 10) => {

  const url = `https://api.tapwallet.io/random_payment/latest?key=${key}&offset=${offset}&limit=${limit}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const updateBlockdropMint = async (key) => {
  
  const url = 'https://api2.tapwallet.io/patoken/blockdrop/recover';
  const data = {
    privKey: key 
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

export const getLatestBlockdropMint = async (id) => {
  
  const url = 'https://api2.tapwallet.io/patoken/blockdrop/index';

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const updateDmtMint = async (key) => {
  
  const url = 'https://api2.tapwallet.io/patoken/dmt/recover';
  const data = {
    privKey: key 
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

export const getLatestDmtMint = async (id) => {
  
  const url = 'https://api2.tapwallet.io/patoken/dmt/index';

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const updateMint = async (id) => {
  
  const url = 'https://api.tapwallet.io/mint/recover';
  const data = {
    id: id
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

export const getLatestMint = async (id) => {
  
  const url = 'https://api.tapwallet.io/mint/index';

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const getSignMainMintText = async (address, amount, salts, number) => {
  
  const url = 'https://api2.tapwallet.io/_signMintWithoutRandomNew';
  const data = {
    address: address,
    amount: parseInt(amount),
    sales: salts
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  let result = await response.text();
  const bytes  = CryptoJS.AES.decrypt(result, password);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  
  return originalText;
}

export const getSignMintText = async (address, amount, salts, number) => {
  
  const url = 'https://api2.tapwallet.io/_signMintWithoutRandomRandom';
  const data = {
    address: address,
    amount: parseInt(amount),
    sales: salts,
    number: number
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  let result = await response.text();
  const bytes  = CryptoJS.AES.decrypt(result, password);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  
  return originalText;
}

export const getPaymentHistory = async () => {
  const url = 'https://api.tapwallet.io/payment_history1';

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const savePaymentHistory = async (value) => {
  const url = 'https://api.tapwallet.io/payment_history1';
  const data = {
    value: value
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const latestDomain = async (key, offset = 1, limit = 20) => {

  const url = `https://api.tapwallet.io/domain/latest?key=${key}&offset=${offset}&limit=${limit}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const registerToken = async (address, transaction) => {
  
  const url = 'https://api2.tapwallet.io/token/register';
  const data = {
    address: address,
    transaction: transaction,
    data: CryptoJS.AES.encrypt(transaction, password).toString(),
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

export const getAllDomains = async (key, offset = 1, limit = 20) => {

  const url = `https://api.tapwallet.io/domain/all?key=${key}&offset=${offset}&limit=${limit}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}



export const getUnatData = async (address, limit, offset, ticker, blk) => {
  const url = `https://api.tapwallet.io/unat?limit=${limit}&offset=${offset}&address=${address}&ticker=${ticker}&blk=${blk}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const getUnatIndex = async (ticker = '', limit = 20, offset = 0, order = 1) => {
  const url = `https://api.tapwallet.io/unat/index?limit=${limit}&offset=${offset}&ticker=${ticker}&order=${order}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const getUnatHolders = async (ticker = '', limit = 20, offset = 0, order = 1) => {
  const url = `https://api.tapwallet.io/unat/holders?limit=${limit}&offset=${offset}&ticker=${ticker}&order=${order}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const getUnatTickers = async (ticker = '', limit = 20, offset = 0) => {
  const url = `https://api.tapwallet.io/unat/ticker?limit=${limit}&offset=${offset}&ticker=${ticker}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const getUnatAddress = async (addressList) => {
  const url = 'https://api.tapwallet.io/unat/address';
  const data = {
    addressList: addressList
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}


export const getInscriptionIds = async (address) => {
  const url = `https://api.brc444.com/fetchOrdinals?address=${address}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const registerDomain = async (_address, domain, address) => {
  
  const url = 'https://api.tapwallet.io/domain/register';
  const data = {
    _address: _address,
    domain: domain,
    address: address
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

}

export const checkDomain = async (domain) => {
  
  const url = 'https://api.tapwallet.io/domain/check';
  const data = {
    domain: domain
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const lockDomain = async (domain) => {
  
  const url = 'https://api.tapwallet.io/domain/lock';
  const data = {
    domain: domain
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const unlockDomain = async (domain) => {
  
  const url = 'https://api.tapwallet.io/domain/unlock';
  const data = {
    domain: domain
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

// export const getUnat = async (inscriptionIds) => {
  
//   const url = 'https://api.tapwallet.io/unat';
//   const data = {
//     inscriptionIds: inscriptionIds
//   };

//   let response  = await fetch(url, {
//     method: 'POST', 
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(data),
//   })

//   return await response.json();
// }


export const calculateFee = (vins, vouts, recommendedFeeRate, includeChangeOutput = 0 ) => {
  if (typeof recommendedFeeRate !== "number" || recommendedFeeRate <= 0)
    throw new Error("Invalid fee rate.");

  const baseTxSize = 10;
  const inSize = 57.5;
  const outSize = 43;
  const txSize = baseTxSize + vins * inSize + vouts * outSize + includeChangeOutput * outSize;
  const fee = Math.round(txSize * recommendedFeeRate);
  return fee;
}

export const updateUnat = async (address) => {
  const url = 'https://api.tapwallet.io/unat/update';
  const data = {
    address: address,
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const getPaymentTx = async (psbtBase64) => {
  
  const url = 'https://api.brc444.com/getPaymentTx';
  const data = {
    psbtBase64: psbtBase64
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}


export const getUnisatBrc20 = async(address, cursor) => {
  const url = 'https://wallet-api.unisat.io/v5/brc20/list';
  const params = new URLSearchParams({
    address: address,
    cursor: cursor,
    size: 100
  });

  const headers = new Headers();
  headers.append('X-Client', 'UniSat Wallet');
  headers.append('X-Version', "1.2.5");
  headers.append('x-address', address);
  headers.append('x-flag', 0 + '');
  headers.append('x-channel', "store");
  headers.append('x-udid', 'UkVxslRzcRkF');

  try {
    const response = await fetch(`${url}?${params}`, {
      method: 'GET', // GET is the default method and can be omitted.
      headers,
      mode: 'cors', cache: 'default'
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data.data;
  } catch (e) {
    console.error('Fetch error:', e.message);
  }
}

export const getOrdinals = async(address, cursor, size = 100) => {
  const url = 'https://wallet-api.unisat.io/v5/ordinals/inscriptions';
  const params = new URLSearchParams({
    address: address,
    cursor: cursor,
    size: size
  });

  const headers = new Headers();
  headers.append('X-Client', 'UniSat Wallet');
  headers.append('X-Version', "1.2.5");
  headers.append('x-address', address);
  headers.append('x-flag', 0 + '');
  headers.append('x-channel', "store");
  headers.append('x-udid', 'UkVxslRzcRkF');

  try {
    const response = await fetch(`${url}?${params}`, {
      method: 'GET', // GET is the default method and can be omitted.
      headers,
      mode: 'cors', cache: 'default'
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data.data;
  } catch (e) {
    console.error('Fetch error:', e.message);
  }
}

export const getInscriptionContent =  async (inscriptionId) => {
  const url = new URL(`https://ordinals.com/content/${inscriptionId}`);
  let data = ""
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    data = await response.text();
  } catch (error) {
    console.error('Error fetching data:', error);
  }
  finally {
    return data;
  }
}


export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

