import React, {useState, useEffect, useCallback} from 'react'
import { 
  Input,
  Popover,
  PopoverHandler,
  PopoverContent,
  Button
} from '@material-tailwind/react';
import { BellAlertIcon, QueueListIcon } from '@heroicons/react/24/outline';
import { getFeeRate } from '../util/inscribe-util';
import { RiBikeLine } from "react-icons/ri";
import { MdOutlineFlight } from "react-icons/md";
import { BsFillLightningChargeFill } from "react-icons/bs";
import { debounce } from 'lodash';
import Connect from './Connect';
import logo from '../assets/imgs/logo.png';
import { latestDomain } from '../util/api';
import { useSelector, useDispatch } from 'react-redux';
import { updateKey } from '../store/action';
import {
  useNavigate,
} from "react-router-dom";

export default function Header(props) {
  const wallet = useSelector(state => state.wallet);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {tab, handle} = props;
  const [key, setKey] = useState("");
  const [feeRates, setFeeRates] = useState({});


  const debouncedSearch = useCallback(debounce( async (value) => {
    let result = await latestDomain(value);
    let domain = "";
    let address = "";
    
    if (result.total == 0) 
      address = value;
    else
    {
      domain = value;
      address = result.data[0].address;
    }

    let keyData = {address: address, domain: domain};
    console.log(keyData);
    dispatch(updateKey(keyData));
    navigate('/explorer')

  }, 300), []); 

  const handleKeyChange = (e) => {
    setKey(e.target.value)
    debouncedSearch(e.target.value)
  }

  const logoClick = () => {
    navigate('/')
  }

  return (
    <div className="flex flex-row justify-center h-[65px] fixed top-0 z-50 w-full border-b-[1px] border-b-[#24242a] md:px-7 px-2 text-white bg-[#0b0b0d]">
      <div className=" flex flex-row justify-between gap-7 items-center w-full">
        <div className="flex flex-row gap-5 items-center">
          <div className="flex flex-row items-center gap-5 ml-10">
            <div className={`${tab == 0 ? 'text-primary' : 'text-white'} font-bold cursor-pointer hover:text-primary text-lg`} onClick={() => handle(0)}>TAPZERO</div>
            <div className={`${tab == 1 ? 'text-primary' : 'text-white'} font-bold cursor-pointer hover:text-primary text-lg`} onClick={() => handle(1)}>TAP</div>
            <div className={`${tab == 3 ? 'text-primary' : 'text-white'} font-bold cursor-pointer hover:text-primary text-lg`} onClick={() => handle(3)}>DMT</div>
            <div className={`${tab == 2 ? 'text-primary' : 'text-white'} font-bold cursor-pointer hover:text-primary text-lg`} onClick={() => handle(2)}>TRANSFER</div>
            <div className={`${tab == 4 ? 'text-primary' : 'text-white'} font-bold cursor-pointer hover:text-primary text-lg`} onClick={() => handle(4)}>RUNE</div>
          </div>
        </div>
      </div>
    </div>
  )
}
